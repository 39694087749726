// apicore
import { notification } from "antd";
import { APICore } from "../../helpers/api/apiCore";

// constants
import { AuthActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  searchShareWith: {},
  helpDeskListing: {},
  relatedTicketFoundRecord: {},
  relatedContentFoundRecord: {},
  searchContentByTitle: {},
  searchTicketBySubject: {},
  loaderSearch: false,
  classificationNode: {},
  classificationNodeChild: {},
  resultTaxoSearchByName: {},
  favoriteTaxonomy: {},
  responseAddToFavTaxo: {},
  removeFavoriteTaxonomyResponse: {},
  myTicketsListing: {},
  myContentListing: {},
  hdTicketsListing: {},
  hdContentListing: {},
  saveTicketInputResponse: {},
  saveContentInputResponse: {},
  statusFilterValue: {},
  contentTypeFilter: {},
  priorityDropdownList: {},
  companyDropdownList: {},
  detailsData: {},
  isFormLoading: false,
  responseEditTicket: {},
  statusAddSharedWithT: {},
  responseEditTaxonomy: {},
  responseDeleteAction: {},
  communicationFrom: {},
  communicationActions: {},
  responseMoveToAnotherHd: {},
  responseDuplicateTicket: {},
  getFileAttachmentResponse: {},
  getDeleteTicketResponse: {},
  communicationThreadsData: {},
  responseRelatedData: {},
  responseUploadData: {},
  responseUploadDataDelete: {},
  responseAddRelatedData: {},
  responseAddAssignTo: {},
  workLogThreads: {},
  communicationThreadsLog: {},
  insertCommunicationResponse: {},
  responseInformationStatus: {},
  responseNotificationUpdate: {},
  responseSearchableUpdate: {},
  responseSearchQuery: {},
  searchQuery: {},
};

type UserData = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.RECEIVE_SHARE_WITH_LISTING
    | AuthActionTypes.RECEIVE_HELP_DESK_DROPDOWN
    | AuthActionTypes.RECEIVE_RELATED_TICKET
    | AuthActionTypes.RECEIVE_RELATED_CONTENT
    | AuthActionTypes.RECEIVE_SEARCH_TICKET_BY_SUBJECT
    | AuthActionTypes.RECEIVE_SEARCH_CONTENT_BY_TITLE
    | AuthActionTypes.ENABLE_SEARCH_LOADER
    | AuthActionTypes.RECEIVE_CLASSIFICATION_NODES
    | AuthActionTypes.RECEIVE_CLASSIFICATION_NODES_CHILD
    | AuthActionTypes.RECEIVE_SEARCH_TAXO_BY_NAME
    | AuthActionTypes.RECEIVE_FAVORITE_TAXO
    | AuthActionTypes.RECEIVE_REMOVE_FAVORITE_TAXO
    | AuthActionTypes.RECEIVE_MY_TICKET_LISTING
    | AuthActionTypes.RECEIVE_MY_CONTENT_LISTING
    | AuthActionTypes.RECEIVE_SAVE_TICKET_INPUT
    | AuthActionTypes.RECEIVE_SAVE_CONTENT_INPUT
    | AuthActionTypes.RECEIVE_HD_TICKET_LISTING
    | AuthActionTypes.RECEIVE_HD_CONTENT_LISTING
    | AuthActionTypes.RECEIVE_RESPONSE_ADD_FAV_TAXO
    | AuthActionTypes.RECEIVE_STATUS_FILTER
    | AuthActionTypes.RECEIVE_CONTENT_TYPE_FILTER
    | AuthActionTypes.RECEIVE_PRIORITY_DROPDOWN
    | AuthActionTypes.RECEIVE_COMPANY_DROPDOWN
    | AuthActionTypes.RECEIVE_DETAILS
    | AuthActionTypes.ENABLE_FORM_LOADING
    | AuthActionTypes.DISABLE_FORM_LOADING
    | AuthActionTypes.RECEIVE_EDIT_TICKET
    | AuthActionTypes.REQUEST_EDIT_SHARED_WITH
    | AuthActionTypes.RECEIVE_EDIT_SHARED_WITH
    | AuthActionTypes.REQUEST_EDIT_TAXO_TICKET
    | AuthActionTypes.RECEIVE_EDIT_TAXO_TICKET
    | AuthActionTypes.RECEIVE_DELETE_RECORD
    | AuthActionTypes.RECEIVE_COMMUNICATION_FROM
    | AuthActionTypes.RECEIVE_COMMUNICATION_ACTIONS
    | AuthActionTypes.RECEIVE_CLASSIFICATION_FROM_CLASS
    | AuthActionTypes.RECEIVE_MOVE_TO_ANOTHER_HD
    | AuthActionTypes.RECEIVE_DUPLICATE
    | AuthActionTypes.RECEIVE_FILE_ATTACHMENT
    | AuthActionTypes.RECEIVE_COMMUNICATION_THREADS
    | AuthActionTypes.RECEIVE_DELETE_TICKET
    | AuthActionTypes.RECEIVE_RELATED_DATA
    | AuthActionTypes.RECEIVE_UPLOAD_DATA
    | AuthActionTypes.RECEIVE_UPLOAD_DATA_DELETE
    | AuthActionTypes.RECEIVE_ADD_RELATED_DATA
    | AuthActionTypes.RECEIVE_ASSIGN_TO
    | AuthActionTypes.RECEIVE_WORK_LOG_THREADS
    | AuthActionTypes.RECEIVE_COMMUNICATION_LOG_THREADS
    | AuthActionTypes.RECEIVE_INSERT_COMMUNICATION
    | AuthActionTypes.RECEIVE_INFORMATION_UPDATE
    | AuthActionTypes.RECEIVE_NOTIFICATION_UPDATE
    | AuthActionTypes.RECEIVE_SEARCHABLE_UPDATE
    | AuthActionTypes.RECEIVE_SEARCH_QUERY
    | AuthActionTypes.ADD_NAV_QUERY;

  payload: {
    actionType?: string;
    data?: UserData | {};
    error?: string;
  };
};

type State = {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
};

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            userSignUp: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.SIGNUP_USER:
      return { ...state, loading: true, userSignUp: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };

    case AuthActionTypes.RECEIVE_SHARE_WITH_LISTING:
      return {
        ...state,
        searchShareWith: action.payload,
      };
    case AuthActionTypes.RECEIVE_HELP_DESK_DROPDOWN:
      return {
        ...state,
        helpDeskListing: action.payload,
      };
    case AuthActionTypes.RECEIVE_RELATED_TICKET:
      return {
        ...state,
        relatedTicketFoundRecord: action.payload,
      };
    case AuthActionTypes.RECEIVE_RELATED_CONTENT:
      return {
        ...state,
        relatedContentFoundRecord: action.payload,
      };
    case AuthActionTypes.RECEIVE_SEARCH_CONTENT_BY_TITLE:
      return {
        ...state,
        searchContentByTitle: action.payload,
        loaderSearch: false,
      };
    case AuthActionTypes.RECEIVE_SEARCH_TICKET_BY_SUBJECT:
      return {
        ...state,
        searchTicketBySubject: action.payload,
        loaderSearch: false,
      };

    case AuthActionTypes.ENABLE_SEARCH_LOADER:
      return {
        ...state,
        loaderSearch: true,
      };
    case AuthActionTypes.RECEIVE_CLASSIFICATION_NODES:
      return {
        ...state,
        classificationNode: action.payload,
      };

    case AuthActionTypes.RECEIVE_CLASSIFICATION_NODES_CHILD:
      return {
        ...state,
        classificationNodeChild: action.payload,
      };
    case AuthActionTypes.RECEIVE_CLASSIFICATION_FROM_CLASS:
      return {
        ...state,
        classificationNodeChild: action.payload,
      };
    case AuthActionTypes.RECEIVE_SEARCH_TAXO_BY_NAME:
      return {
        ...state,
        resultTaxoSearchByName: action.payload,
      };
    case AuthActionTypes.RECEIVE_FAVORITE_TAXO:
      return {
        ...state,
        favoriteTaxonomy: action.payload,
      };
    case AuthActionTypes.RECEIVE_REMOVE_FAVORITE_TAXO:
      return {
        ...state,
        removeFavoriteTaxonomyResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_MY_TICKET_LISTING:
      return {
        ...state,
        myTicketsListing: action.payload,
      };
    case AuthActionTypes.RECEIVE_HD_TICKET_LISTING:
      return {
        ...state,
        hdTicketsListing: action.payload,
      };
    case AuthActionTypes.RECEIVE_HD_CONTENT_LISTING:
      return {
        ...state,
        hdContentListing: action.payload,
      };
    case AuthActionTypes.RECEIVE_MY_CONTENT_LISTING:
      return {
        ...state,
        myContentListing: action.payload,
      };
    case AuthActionTypes.RECEIVE_SAVE_TICKET_INPUT:
      return {
        ...state,
        saveTicketInputResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_SAVE_CONTENT_INPUT:
      return {
        ...state,
        saveContentInputResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_RESPONSE_ADD_FAV_TAXO:
      return {
        ...state,
        responseAddToFavTaxo: action.payload,
      };
    case AuthActionTypes.RECEIVE_STATUS_FILTER:
      return {
        ...state,
        statusFilterValue: action.payload,
      };
    case AuthActionTypes.RECEIVE_CONTENT_TYPE_FILTER:
      return {
        ...state,
        contentTypeFilter: action.payload,
      };
    case AuthActionTypes.RECEIVE_PRIORITY_DROPDOWN:
      return {
        ...state,
        priorityDropdownList: action.payload,
      };
    case AuthActionTypes.RECEIVE_COMPANY_DROPDOWN:
      return {
        ...state,
        companyDropdownList: action.payload,
      };
    case AuthActionTypes.RECEIVE_DETAILS:
      return {
        ...state,
        detailsData: action.payload,
      };
    case AuthActionTypes.ENABLE_FORM_LOADING:
      return {
        ...state,
        isFormLoading: true,
      };
    case AuthActionTypes.DISABLE_FORM_LOADING:
      return {
        ...state,
        isFormLoading: false,
      };
    case AuthActionTypes.RECEIVE_EDIT_TICKET:
      return {
        ...state,
        responseEditTicket: action.payload,
      };
    case AuthActionTypes.RECEIVE_EDIT_SHARED_WITH:
      return {
        ...state,
        statusAddSharedWithT: action.payload,
      };
    case AuthActionTypes.RECEIVE_EDIT_TAXO_TICKET:
      return {
        ...state,
        responseEditTaxonomy: action.payload,
      };
    case AuthActionTypes.RECEIVE_DELETE_RECORD:
      return {
        ...state,
        responseDeleteAction: action.payload,
      };
    case AuthActionTypes.RECEIVE_COMMUNICATION_FROM:
      return {
        ...state,
        communicationFrom: action.payload,
      };
    case AuthActionTypes.RECEIVE_COMMUNICATION_ACTIONS:
      return {
        ...state,
        communicationActions: action.payload,
      };
    case AuthActionTypes.RECEIVE_MOVE_TO_ANOTHER_HD:
      return {
        ...state,
        responseMoveToAnotherHd: action.payload,
      };
    case AuthActionTypes.RECEIVE_DUPLICATE:
      return {
        ...state,
        responseDuplicateTicket: action.payload,
      };
    case AuthActionTypes.RECEIVE_FILE_ATTACHMENT:
      return {
        ...state,
        getFileAttachmentResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_DELETE_TICKET:
      return {
        ...state,
        getDeleteTicketResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_COMMUNICATION_THREADS:
      return {
        ...state,
        communicationThreadsData: action.payload,
      };
    case AuthActionTypes.RECEIVE_RELATED_DATA:
      return {
        ...state,
        responseRelatedData: action.payload,
      };
    case AuthActionTypes.RECEIVE_UPLOAD_DATA:
      return {
        ...state,
        responseUploadData: action.payload,
      };
    case AuthActionTypes.RECEIVE_UPLOAD_DATA_DELETE:
      return {
        ...state,
        responseUploadDataDelete: action.payload,
      };
    case AuthActionTypes.RECEIVE_ADD_RELATED_DATA:
      return {
        ...state,
        responseAddRelatedData: action.payload,
      };
    case AuthActionTypes.RECEIVE_ASSIGN_TO:
      return {
        ...state,
        responseAddAssignTo: action.payload,
      };
    case AuthActionTypes.RECEIVE_WORK_LOG_THREADS:
      return {
        ...state,
        workLogThreads: action.payload,
      };
    case AuthActionTypes.RECEIVE_COMMUNICATION_LOG_THREADS:
      return {
        ...state,
        communicationThreadsLog: action.payload,
      };
    case AuthActionTypes.RECEIVE_INSERT_COMMUNICATION:
      return {
        ...state,
        insertCommunicationResponse: action.payload,
      };
    case AuthActionTypes.RECEIVE_INFORMATION_UPDATE:
      return {
        ...state,
        responseInformationStatus: action.payload,
      };
    case AuthActionTypes.RECEIVE_NOTIFICATION_UPDATE:
      return {
        ...state,
        responseNotificationUpdate: action.payload,
      };
    case AuthActionTypes.RECEIVE_SEARCHABLE_UPDATE:
      return {
        ...state,
        responseSearchableUpdate: action.payload,
      };
    case AuthActionTypes.RECEIVE_SEARCH_QUERY:
      return {
        ...state,
        responseSearchQuery: action.payload,
      };
    case AuthActionTypes.ADD_NAV_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Auth;
