// constants
import { AuthActionTypes } from "./constants";

export type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.REQUEST_SHARE_WITH_LISTING
    | AuthActionTypes.RECEIVE_SHARE_WITH_LISTING
    | AuthActionTypes.REQUEST_HELP_DESK_DROPDOWN
    | AuthActionTypes.RECEIVE_HELP_DESK_DROPDOWN
    | AuthActionTypes.REQUEST_RELATED_TICKET
    | AuthActionTypes.RECEIVE_RELATED_TICKET
    | AuthActionTypes.REQUEST_RELATED_CONTENT
    | AuthActionTypes.RECEIVE_RELATED_CONTENT
    | AuthActionTypes.REQUEST_SEARCH_CONTENT_BY_TITLE
    | AuthActionTypes.RECEIVE_SEARCH_CONTENT_BY_TITLE
    | AuthActionTypes.REQUEST_SEARCH_TICKET_BY_SUBJECT
    | AuthActionTypes.RECEIVE_SEARCH_TICKET_BY_SUBJECT
    | AuthActionTypes.ENABLE_SEARCH_LOADER
    | AuthActionTypes.REQUEST_CLASSIFICATION_NODES
    | AuthActionTypes.RECEIVE_CLASSIFICATION_NODES
    | AuthActionTypes.REQUEST_CLASSIFICATION_NODES_CHILD
    | AuthActionTypes.RECEIVE_CLASSIFICATION_NODES_CHILD
    | AuthActionTypes.REQUEST_SEARCH_TAXO_BY_NAME
    | AuthActionTypes.RECEIVE_SEARCH_TAXO_BY_NAME
    | AuthActionTypes.REQUEST_FAVORITE_TAXO
    | AuthActionTypes.RECEIVE_FAVORITE_TAXO
    | AuthActionTypes.REQUEST_ADD_FAVORITE_TAXO
    | AuthActionTypes.RECEIVE_ADD_FAVORITE_TAXO
    | AuthActionTypes.REQUEST_REMOVE_FAVORITE_TAXO
    | AuthActionTypes.RECEIVE_REMOVE_FAVORITE_TAXO
    | AuthActionTypes.REQUEST_MY_TICKET_LISTING
    | AuthActionTypes.RECEIVE_MY_TICKET_LISTING
    | AuthActionTypes.REQUEST_MY_CONTENT_LISTING
    | AuthActionTypes.RECEIVE_MY_CONTENT_LISTING
    | AuthActionTypes.REQUEST_SAVE_TICKET_INPUT
    | AuthActionTypes.RECEIVE_SAVE_TICKET_INPUT
    | AuthActionTypes.REQUEST_SAVE_CONTENT_INPUT
    | AuthActionTypes.RECEIVE_SAVE_CONTENT_INPUT
    | AuthActionTypes.REQUEST_HD_TICKET_LISTING
    | AuthActionTypes.RECEIVE_HD_TICKET_LISTING
    | AuthActionTypes.REQUEST_HD_CONTENT_LISTING
    | AuthActionTypes.RECEIVE_HD_CONTENT_LISTING
    | AuthActionTypes.RECEIVE_RESPONSE_ADD_FAV_TAXO
    | AuthActionTypes.REQUEST_STATUS_FILTER
    | AuthActionTypes.RECEIVE_STATUS_FILTER
    | AuthActionTypes.REQUEST_CONTENT_TYPE_FILTER
    | AuthActionTypes.RECEIVE_CONTENT_TYPE_FILTER
    | AuthActionTypes.RECEIVE_PRIORITY_DROPDOWN
    | AuthActionTypes.REQUEST_PRIORITY_DROPDOWN
    | AuthActionTypes.REQUEST_COMPANY_DROPDOWN
    | AuthActionTypes.RECEIVE_COMPANY_DROPDOWN
    | AuthActionTypes.REQUEST_DETAILS
    | AuthActionTypes.RECEIVE_DETAILS
    | AuthActionTypes.ENABLE_FORM_LOADING
    | AuthActionTypes.DISABLE_FORM_LOADING
    | AuthActionTypes.REQUEST_EDIT_TICKET
    | AuthActionTypes.RECEIVE_EDIT_TICKET
    | AuthActionTypes.REQUEST_EDIT_SHARED_WITH
    | AuthActionTypes.RECEIVE_EDIT_SHARED_WITH
    | AuthActionTypes.REQUEST_EDIT_TAXO_TICKET
    | AuthActionTypes.RECEIVE_EDIT_TAXO_TICKET
    | AuthActionTypes.REQUEST_DELETE_RECORD
    | AuthActionTypes.RECEIVE_DELETE_RECORD
    | AuthActionTypes.REQUEST_COMMUNICATION_FROM
    | AuthActionTypes.RECEIVE_COMMUNICATION_FROM
    | AuthActionTypes.REQUEST_COMMUNICATION_ACTIONS
    | AuthActionTypes.RECEIVE_COMMUNICATION_ACTIONS
    | AuthActionTypes.REQUEST_CLASSIFICATION_FROM_CLASS
    | AuthActionTypes.RECEIVE_CLASSIFICATION_FROM_CLASS
    | AuthActionTypes.REQUEST_MOVE_TO_ANOTHER_HD
    | AuthActionTypes.RECEIVE_MOVE_TO_ANOTHER_HD
    | AuthActionTypes.REQUEST_DUPLICATE
    | AuthActionTypes.RECEIVE_DUPLICATE
    | AuthActionTypes.REQUEST_FILE_ATTACHMENT
    | AuthActionTypes.RECEIVE_FILE_ATTACHMENT
    | AuthActionTypes.REQUEST_DELETE_TICKET
    | AuthActionTypes.RECEIVE_DELETE_TICKET
    | AuthActionTypes.REQUEST_COMMUNICATION_THREADS
    | AuthActionTypes.RECEIVE_COMMUNICATION_THREADS
    | AuthActionTypes.REQUEST_RELATED_DATA
    | AuthActionTypes.RECEIVE_RELATED_DATA
    | AuthActionTypes.REQUEST_UPLOAD_DATA
    | AuthActionTypes.RECEIVE_UPLOAD_DATA
    | AuthActionTypes.REQUEST_UPLOAD_DATA_DELETE
    | AuthActionTypes.RECEIVE_UPLOAD_DATA_DELETE
    | AuthActionTypes.REQUEST_ADD_RELATED_DATA
    | AuthActionTypes.RECEIVE_ADD_RELATED_DATA
    | AuthActionTypes.REQUEST_ASSIGN_TO
    | AuthActionTypes.RECEIVE_ASSIGN_TO
    | AuthActionTypes.REQUEST_WORK_LOG_THREADS
    | AuthActionTypes.RECEIVE_WORK_LOG_THREADS
    | AuthActionTypes.REQUEST_COMMUNICATION_LOG_THREADS
    | AuthActionTypes.RECEIVE_COMMUNICATION_LOG_THREADS
    | AuthActionTypes.REQUEST_INSERT_COMMUNICATION
    | AuthActionTypes.RECEIVE_INSERT_COMMUNICATION
    | AuthActionTypes.REQUEST_INFORMATION_UPDATE
    | AuthActionTypes.RECEIVE_INFORMATION_UPDATE
    | AuthActionTypes.REQUEST_NOTIFICATION_UPDATE
    | AuthActionTypes.RECEIVE_NOTIFICATION_UPDATE
    | AuthActionTypes.REQUEST_SEARCHABLE_UPDATE
    | AuthActionTypes.RECEIVE_SEARCHABLE_UPDATE
    | AuthActionTypes.REQUEST_SEARCH_QUERY
    | AuthActionTypes.RECEIVE_SEARCH_QUERY
    | AuthActionTypes.ADD_NAV_QUERY;

  payload?: {} | string;
};

type UserData = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password },
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});

export const requestShareWithListing = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SHARE_WITH_LISTING,
    payload: payload,
  };
};

export const receiveShareWithListing = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SHARE_WITH_LISTING,
    payload: payload,
  };
};

export const requestHelpDeskListing = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_HELP_DESK_DROPDOWN,
    payload: payload,
  };
};

export const receiveHelpDeskListing = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_HELP_DESK_DROPDOWN,
    payload: payload,
  };
};

export const requestRelatedTicket = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_RELATED_TICKET,
    payload: payload,
  };
};

export const receiveRelatedTicket = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_RELATED_TICKET,
    payload: payload,
  };
};

export const requestRelatedContent = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_RELATED_CONTENT,
    payload: payload,
  };
};

export const receiveRelatedContent = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_RELATED_CONTENT,
    payload: payload,
  };
};

export const requestSearchContentByTitle = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SEARCH_CONTENT_BY_TITLE,
    payload: payload,
  };
};

export const receiveSearchContentByTitle = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SEARCH_CONTENT_BY_TITLE,
    payload: payload,
  };
};

export const requestSearchTicketBySubject = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SEARCH_TICKET_BY_SUBJECT,
    payload: payload,
  };
};

export const receiveSearchTicketBySubject = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SEARCH_TICKET_BY_SUBJECT,
    payload: payload,
  };
};

export const enableSearchLoaderAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.ENABLE_SEARCH_LOADER,
    payload: payload,
  };
};

export const requestClassificationNodesAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_CLASSIFICATION_NODES,
    payload: payload,
  };
};

export const receiveClassificationNodesAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_CLASSIFICATION_NODES,
    payload: payload,
  };
};

export const requestClassificationNodesChildAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_CLASSIFICATION_NODES_CHILD,
    payload: payload,
  };
};

export const receiveClassificationNodesChildAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_CLASSIFICATION_NODES_CHILD,
    payload: payload,
  };
};

export const requestSearchTaxoByNameAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SEARCH_TAXO_BY_NAME,
    payload: payload,
  };
};

export const receiveSearchTaxoByNameAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SEARCH_TAXO_BY_NAME,
    payload: payload,
  };
};

export const requestFavoriteTaxoAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_FAVORITE_TAXO,
    payload: payload,
  };
};

export const receiveFavouriteTaxoAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_FAVORITE_TAXO,
    payload: payload,
  };
};

export const receiveResponseAddFavouriteTaxoAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_RESPONSE_ADD_FAV_TAXO,
    payload: payload,
  };
};

export const requestAddFavoriteTaxoAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_ADD_FAVORITE_TAXO,
    payload: payload,
  };
};

export const receiveAddFavouriteTaxoAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_ADD_FAVORITE_TAXO,
    payload: payload,
  };
};

export const requestRemoveFavoriteTaxoAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_REMOVE_FAVORITE_TAXO,
    payload: payload,
  };
};

export const receiveRemoveFavoriteTaxoAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_REMOVE_FAVORITE_TAXO,
    payload: payload,
  };
};

export const requestMyTicketsListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_MY_TICKET_LISTING,
    payload: payload,
  };
};

export const receiveMyTicketsListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_MY_TICKET_LISTING,
    payload: payload,
  };
};

export const requestMyContentListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_MY_CONTENT_LISTING,
    payload: payload,
  };
};

export const receiveMyContentListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_MY_CONTENT_LISTING,
    payload: payload,
  };
};

export const requestHDContentListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_HD_CONTENT_LISTING,
    payload: payload,
  };
};

export const receiveHDContentListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_HD_CONTENT_LISTING,
    payload: payload,
  };
};
export const requestHDTicketListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_HD_TICKET_LISTING,
    payload: payload,
  };
};

export const receiveHDTicketListingAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_HD_TICKET_LISTING,
    payload: payload,
  };
};

export const requestSaveTicketInputAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SAVE_TICKET_INPUT,
    payload: payload,
  };
};

export const receiveSaveTicketInputAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SAVE_TICKET_INPUT,
    payload: payload,
  };
};
export const requestSaveContentInputAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SAVE_CONTENT_INPUT,
    payload: payload,
  };
};

export const receiveSaveContentInputAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SAVE_CONTENT_INPUT,
    payload: payload,
  };
};

export const requestStatusFilterAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_STATUS_FILTER,
    payload: payload,
  };
};

export const receiveStatusFilterAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_STATUS_FILTER,
    payload: payload,
  };
};

export const requestContentTypeFilterAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_CONTENT_TYPE_FILTER,
    payload: payload,
  };
};

export const receiveContentTypeFilterAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_CONTENT_TYPE_FILTER,
    payload: payload,
  };
};

export const requestPriorityDropdownAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_PRIORITY_DROPDOWN,
    payload: payload,
  };
};

export const receivePriorityDropdownAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_PRIORITY_DROPDOWN,
    payload: payload,
  };
};

export const requestCompanyDropdownAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_COMPANY_DROPDOWN,
    payload: payload,
  };
};

export const receiveCompanyDropdownAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_COMPANY_DROPDOWN,
    payload: payload,
  };
};

export const requestDetailsAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_DETAILS,
    payload: payload,
  };
};

export const receiveDetailsAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_DETAILS,
    payload: payload,
  };
};

export const enableFormLoading = (): AuthActionType => {
  return {
    type: AuthActionTypes.ENABLE_FORM_LOADING,
  };
};

export const disableFormLoading = (): AuthActionType => {
  return {
    type: AuthActionTypes.DISABLE_FORM_LOADING,
  };
};

export const requestEditTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_EDIT_TICKET,
    payload: payload,
  };
};

export const receiveEditTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_EDIT_TICKET,
    payload: payload,
  };
};

export const requestEditSharedWithTicketAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_EDIT_SHARED_WITH,
    payload: payload,
  };
};

export const receiveEditSharedWithTicketAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_EDIT_SHARED_WITH,
    payload: payload,
  };
};

export const requestEditTaxoTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_EDIT_TAXO_TICKET,
    payload: payload,
  };
};

export const receiveEditTaxoTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_EDIT_TAXO_TICKET,
    payload: payload,
  };
};

export const requestDeleteAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_DELETE_RECORD,
    payload: payload,
  };
};

export const receiveDeleteAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_DELETE_RECORD,
    payload: payload,
  };
};

export const requestCommunicationFromAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_COMMUNICATION_FROM,
    payload: payload,
  };
};

export const receiveCommunicationFromAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_COMMUNICATION_FROM,
    payload: payload,
  };
};

export const requestCommunicationActionsAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_COMMUNICATION_ACTIONS,
    payload: payload,
  };
};

export const receiveCommunicationActionsAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_COMMUNICATION_ACTIONS,
    payload: payload,
  };
};

export const requestClassificationFromClassAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_CLASSIFICATION_FROM_CLASS,
    payload: payload,
  };
};

export const receiveClassificationFromClassAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_CLASSIFICATION_FROM_CLASS,
    payload: payload,
  };
};

export const requestMoveToAnotherHdAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_MOVE_TO_ANOTHER_HD,
    payload: payload,
  };
};

export const receiveMoveToAnotherHdAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_MOVE_TO_ANOTHER_HD,
    payload: payload,
  };
};

export const requestDuplicateAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_DUPLICATE,
    payload: payload,
  };
};

export const receiveDuplicateAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_DUPLICATE,
    payload: payload,
  };
};

export const requestFileAttachmentAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_FILE_ATTACHMENT,
    payload: payload,
  };
};

export const receiveFileAttachmentAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_FILE_ATTACHMENT,
    payload: payload,
  };
};

export const requestDeleteTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_DELETE_TICKET,
    payload: payload,
  };
};

export const receiveDeleteTicketAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_DELETE_TICKET,
    payload: payload,
  };
};

export const requestCommunicationThreadsAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_COMMUNICATION_THREADS,
    payload: payload,
  };
};

export const receiveCommunicationThreadsAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_COMMUNICATION_THREADS,
    payload: payload,
  };
};

export const requestRelatedDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_RELATED_DATA,
    payload: payload,
  };
};

export const receiveRelatedDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_RELATED_DATA,
    payload: payload,
  };
};

export const requestUploadDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_UPLOAD_DATA,
    payload: payload,
  };
};

export const receiveUploadDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_UPLOAD_DATA,
    payload: payload,
  };
};

export const requestUploadDataDeleteAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_UPLOAD_DATA_DELETE,
    payload: payload,
  };
};

export const receiveUploadDataDeleteAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_UPLOAD_DATA_DELETE,
    payload: payload,
  };
};

export const requestAddRelatedDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_ADD_RELATED_DATA,
    payload: payload,
  };
};

export const receiveAddRelatedDataAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_ADD_RELATED_DATA,
    payload: payload,
  };
};

export const requestAssignToAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_ASSIGN_TO,
    payload: payload,
  };
};

export const receiveAssignToAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_ASSIGN_TO,
    payload: payload,
  };
};

export const requestWorkLogThreadsAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_WORK_LOG_THREADS,
    payload: payload,
  };
};

export const receiveWorkLogThreadsAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_WORK_LOG_THREADS,
    payload: payload,
  };
};

export const requestCommunicationLogAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_COMMUNICATION_LOG_THREADS,
    payload: payload,
  };
};

export const receiveCommunicationLogAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_COMMUNICATION_LOG_THREADS,
    payload: payload,
  };
};

export const requestInsertCommunicationAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_INSERT_COMMUNICATION,
    payload: payload,
  };
};

export const receiveInsertCommunicationAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_INSERT_COMMUNICATION,
    payload: payload,
  };
};

export const requestInformationUpdateAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_INFORMATION_UPDATE,
    payload: payload,
  };
};

export const receiveInformationUpdateAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_INFORMATION_UPDATE,
    payload: payload,
  };
};

export const requestNotificationUpdateAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_NOTIFICATION_UPDATE,
    payload: payload,
  };
};

export const receiveNotificationUpdateAction = (
  payload: any
): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_NOTIFICATION_UPDATE,
    payload: payload,
  };
};

export const requestSearchableUpdateAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SEARCHABLE_UPDATE,
    payload: payload,
  };
};

export const receiveSearchableUpdateAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SEARCHABLE_UPDATE,
    payload: payload,
  };
};

export const requestSearchQueryAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.REQUEST_SEARCH_QUERY,
    payload: payload,
  };
};

export const receiveSearchQueryAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.RECEIVE_SEARCH_QUERY,
    payload: payload,
  };
};

export const addNavQueryAction = (payload: any): AuthActionType => {
  return {
    type: AuthActionTypes.ADD_NAV_QUERY,
    payload: payload,
  };
};
