import {
  all,
  fork,
  put,
  takeEvery,
  call,
  takeLeading,
  takeLatest,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  getUserDetails as getUserDetails,
  forgotPassword as forgotPasswordApi,
  requestTicketInputShareWithListing,
  requestHelpDeskDropdown,
  helperRequestRelatedTicket,
  helperRequestRelatedContent,
  helperRequestSearchContentByTitle,
  helperRequestSearchTicketBySubject,
  helperRequestClassificationNode,
  helperRequestSearchTaxoByName,
  helperRequestFavoriteTaxo,
  helperRequestAddFavoriteTaxo,
  helperRequestRemoveFavoriteTaxo,
  helperRequestMyTicketListing,
} from "../../helpers/";

// actions
import {
  authApiResponseSuccess,
  authApiResponseError,
  receiveShareWithListing,
  receiveHelpDeskListing,
  receiveRelatedTicket,
  receiveRelatedContent,
  receiveSearchTicketBySubject,
  receiveSearchContentByTitle,
  receiveClassificationNodesAction,
  receiveSearchTaxoByNameAction,
  receiveClassificationNodesChildAction,
  receiveFavouriteTaxoAction,
  receiveRemoveFavoriteTaxoAction,
  receiveMyTicketsListingAction,
  receiveMyContentListingAction,
  receiveSaveTicketInputAction,
  receiveSaveContentInputAction,
  receiveHDContentListingAction,
  receiveHDTicketListingAction,
  receiveResponseAddFavouriteTaxoAction,
  receiveStatusFilterAction,
  receiveContentTypeFilterAction,
  receivePriorityDropdownAction,
  receiveCompanyDropdownAction,
  receiveDetailsAction,
  receiveEditTicketAction,
  receiveEditSharedWithTicketAction,
  receiveEditTaxoTicketAction,
  receiveDeleteAction,
  receiveCommunicationFromAction,
  receiveCommunicationActionsAction,
  receiveClassificationFromClassAction,
  receiveMoveToAnotherHdAction,
  receiveDuplicateAction,
  receiveFileAttachmentAction,
  receiveDeleteTicketAction,
  receiveCommunicationThreadsAction,
  receiveRelatedDataAction,
  receiveUploadDataAction,
  receiveUploadDataDeleteAction,
  receiveAddRelatedDataAction,
  receiveAssignToAction,
  receiveWorkLogThreadsAction,
  receiveCommunicationLogAction,
  receiveInsertCommunicationAction,
  receiveInformationUpdateAction,
  receiveNotificationUpdateAction,
  receiveSearchableUpdateAction,
  receiveSearchQueryAction,
} from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import {
  APIRequest,
  helperRequestCompanyDropdownList,
  helperRequestContentTypeFilter,
  helperRequestHDContentListing,
  helperRequestHDTicketListing,
  helperRequestMyContentListing,
  helperRequestPriorityDropdownList,
  helperRequestSaveContentInput,
  helperRequestSaveTicketInput,
  helperRequestStatusFilter,
} from "../../helpers/api/auth";

type UserData = {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
};
interface RequestShareWithListingAction {
  type: typeof AuthActionTypes.REQUEST_SHARE_WITH_LISTING;
  payload: any;
}
interface RequestHelpDeskDropdown {
  type: typeof AuthActionTypes.REQUEST_HELP_DESK_DROPDOWN;
  payload: any;
}
interface RequestRelatedTicket {
  type: typeof AuthActionTypes.REQUEST_RELATED_TICKET;
  payload: any;
}

interface RequestRelatedContent {
  type: typeof AuthActionTypes.REQUEST_RELATED_TICKET;
  payload: any;
}

interface RequestSearchContentByTitle {
  type: typeof AuthActionTypes.REQUEST_SEARCH_CONTENT_BY_TITLE;
  payload: any;
}

interface RequestSearchTicketBySubject {
  type: typeof AuthActionTypes.REQUEST_SEARCH_TICKET_BY_SUBJECT;
  payload: any;
}

interface RequestClassificationNodesAction {
  type: typeof AuthActionTypes.REQUEST_CLASSIFICATION_NODES;
  payload: any;
}

interface RequestClassificationNodesChildAction {
  type: typeof AuthActionTypes.REQUEST_CLASSIFICATION_NODES_CHILD;
  payload: any;
}

interface RequestTaxoByName {
  type: typeof AuthActionTypes.REQUEST_SEARCH_TAXO_BY_NAME;
  payload: any;
}

interface RequestFavoriteTaxo {
  type: typeof AuthActionTypes.REQUEST_FAVORITE_TAXO;
  payload: any;
}

interface RequestAddFavoriteTaxo {
  type: typeof AuthActionTypes.REQUEST_ADD_FAVORITE_TAXO;
  payload: any;
}

interface RequestRemoveFavoriteTaxo {
  type: typeof AuthActionTypes.REQUEST_REMOVE_FAVORITE_TAXO;
  payload: any;
}
interface RequestSaveTicketInput {
  type: typeof AuthActionTypes.REQUEST_SAVE_TICKET_INPUT;
  payload: any;
}
interface RequestSaveContentInput {
  type: typeof AuthActionTypes.REQUEST_SAVE_CONTENT_INPUT;
}
interface RequestStatusFilter {
  type: typeof AuthActionTypes.REQUEST_STATUS_FILTER;
  payload: string;
}

interface RequestSearchQuery {
  type: typeof AuthActionTypes.REQUEST_SEARCH_QUERY;
  payload: string;
}

interface RequestDetails {
  type: typeof AuthActionTypes.REQUEST_DETAILS;
  payload: {
    url: string;
    request: string;
  };
}

interface RequestCommunicationFrom {
  type: typeof AuthActionTypes.REQUEST_COMMUNICATION_FROM;
  payload: {
    url: string;
    request: string;
  };
}
interface RequestCommunicationActions {
  type: typeof AuthActionTypes.REQUEST_COMMUNICATION_ACTIONS;
  payload: any;
}
interface requestClassificationFromClass {
  type: typeof AuthActionTypes.REQUEST_CLASSIFICATION_FROM_CLASS;
  payload: any;
}

interface requestMoveToAnotherHd {
  type: typeof AuthActionTypes.REQUEST_MOVE_TO_ANOTHER_HD;
  payload: any;
}

interface requestDuplicate {
  type: typeof AuthActionTypes.REQUEST_DUPLICATE;
  payload: any;
}
interface requestFileAttachment {
  type: typeof AuthActionTypes.REQUEST_FILE_ATTACHMENT;
  payload: any;
}
interface requestDeleteTicket {
  type: typeof AuthActionTypes.REQUEST_DELETE_TICKET;
  payload: any;
}

interface RequestCommunicationThreads {
  type: typeof AuthActionTypes.REQUEST_COMMUNICATION_THREADS;
  payload: any;
}

interface RequestRelatedData {
  type: typeof AuthActionTypes.REQUEST_RELATED_DATA;
  payload: any;
}
interface RequestUploadData {
  type: typeof AuthActionTypes.REQUEST_UPLOAD_DATA;
  payload: any;
}

interface RequestUploadDataDelete {
  type: typeof AuthActionTypes.REQUEST_UPLOAD_DATA_DELETE;
  payload: any;
}
interface RequestAddRelatedData {
  type: typeof AuthActionTypes.REQUEST_ADD_RELATED_DATA;
  payload: any;
}

interface RequestAssignToUser {
  type: typeof AuthActionTypes.REQUEST_ASSIGN_TO;
  payload: any;
}

interface RequestWorkLog {
  type: typeof AuthActionTypes.REQUEST_WORK_LOG_THREADS;
  payload: any;
}

interface RequestCommunicationLog {
  type: typeof AuthActionTypes.REQUEST_COMMUNICATION_LOG_THREADS;
  payload: any;
}
interface RequestInsertCommunication {
  type: typeof AuthActionTypes.REQUEST_INSERT_COMMUNICATION;
  payload: any;
}

interface RequestInformationStatus {
  type: typeof AuthActionTypes.REQUEST_INFORMATION_UPDATE;
  payload: any;
}

interface RequestNotificationStatus {
  type: typeof AuthActionTypes.REQUEST_NOTIFICATION_UPDATE;
  payload: any;
}

interface RequestSearchableStatus {
  type: typeof AuthActionTypes.REQUEST_SEARCHABLE_UPDATE;
  payload: any;
}

interface RequestEditTicketDetails {
  type: typeof AuthActionTypes.REQUEST_EDIT_TICKET;
  payload: {
    url: string;
    request: string;
    body: {
      str_subject: string;
      str_issue_details: string;
      n_priority: string;
      str_due_date: string;
      str_priority_reason: string;
      str_submitted_for_name: string;
      str_submitted_for_id: string;
      n_help_desk: number;
      str_keywords: string;
      str_answer_summary: string;
    };
  };
}
interface RequestDelete {
  type: typeof AuthActionTypes.REQUEST_DETAILS;
  payload: {
    url: string;
    request: string;
  };
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { email, password },
  type,
}: UserData): SagaIterator {
  try {
    let str_username = email;
    let str_password = password;
    const response = yield call(loginApi, { str_username, str_password });
    localStorage.setItem(
      "userData",
      JSON.stringify(yield call(getUserDetails))
    );
    yield put(
      authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data)
    );
  } catch (error: any) {}
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    // localStorage.clear();
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* requestShareWithListingTicketInput(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SHARE_WITH_LISTING,
    function* ({ payload }: RequestShareWithListingAction) {
      try {
        const response: any = yield call(
          requestTicketInputShareWithListing,
          payload
        );

        yield put(receiveShareWithListing(response));
      } catch (error) {}
    }
  );
}

export function* requestHelpDeskListingDropdown(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_HELP_DESK_DROPDOWN,
    function* ({ payload }: RequestHelpDeskDropdown) {
      try {
        const response: any = yield call(requestHelpDeskDropdown);

        yield put(receiveHelpDeskListing(response));
      } catch (error) {}
    }
  );
}

export function* requestRelatedTicketSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_RELATED_TICKET,
    function* ({ payload }: RequestRelatedTicket) {
      try {
        const response: any = yield call(helperRequestRelatedTicket, payload);

        yield put(receiveRelatedTicket(response));
      } catch (error) {}
    }
  );
}

export function* requestRelatedContentSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_RELATED_CONTENT,
    function* ({ payload }: RequestRelatedContent) {
      try {
        const response: any = yield call(helperRequestRelatedContent, payload);

        yield put(receiveRelatedContent(response));
      } catch (error) {}
    }
  );
}

export function* requestSearchContentByTitleSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SEARCH_CONTENT_BY_TITLE,
    function* ({ payload }: RequestSearchContentByTitle) {
      try {
        const response: any = yield call(
          helperRequestSearchContentByTitle,
          payload
        );
        yield put(receiveSearchContentByTitle(response));
      } catch (error) {}
    }
  );
}

export function* requestSearchTicketBySubjectSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SEARCH_TICKET_BY_SUBJECT,
    function* ({ payload }: RequestSearchTicketBySubject) {
      try {
        const response: any = yield call(
          helperRequestSearchTicketBySubject,
          payload
        );

        yield put(receiveSearchTicketBySubject(response));
      } catch (error) {}
    }
  );
}

export function* requestClassificationNodeSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_CLASSIFICATION_NODES,
    function* ({ payload }: RequestClassificationNodesAction) {
      try {
        const response: any = yield call(
          helperRequestClassificationNode,
          payload
        );

        yield put(receiveClassificationNodesAction(response));
      } catch (error) {}
    }
  );
}

export function* requestClassificationNodeChildSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_CLASSIFICATION_NODES_CHILD,
    function* ({ payload }: RequestClassificationNodesChildAction) {
      try {
        const response: any = yield call(
          helperRequestClassificationNode,
          payload
        );
        yield put(receiveClassificationNodesChildAction(response));
      } catch (error) {}
    }
  );
}

export function* requestSearchTaxoByNameSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SEARCH_TAXO_BY_NAME,
    function* ({ payload }: RequestTaxoByName) {
      try {
        const response: any = yield call(
          helperRequestSearchTaxoByName,
          payload
        );

        yield put(receiveSearchTaxoByNameAction(response));
      } catch (error) {}
    }
  );
}

export function* requestFavoriteTaxoSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_FAVORITE_TAXO,
    function* ({ payload }: RequestFavoriteTaxo) {
      try {
        const response: any = yield call(helperRequestFavoriteTaxo, payload);

        yield put(receiveFavouriteTaxoAction(response));
      } catch (error) {}
    }
  );
}

export function* requestAddFavoriteTaxoSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_ADD_FAVORITE_TAXO,
    function* ({ payload }: RequestFavoriteTaxo) {
      try {
        const response: any = yield call(helperRequestAddFavoriteTaxo, payload);

        yield put(receiveResponseAddFavouriteTaxoAction(response));
      } catch (error) {}
    }
  );
}

export function* requestRemoveFavoriteTaxoSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_REMOVE_FAVORITE_TAXO,
    function* ({ payload }: RequestRemoveFavoriteTaxo) {
      try {
        const response: any = yield call(
          helperRequestRemoveFavoriteTaxo,
          payload
        );
        yield put(receiveRemoveFavoriteTaxoAction(response));
      } catch (error) {}
    }
  );
}

export function* requestMyTicketsListing(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_MY_TICKET_LISTING,
    function* ({ payload }: RequestRemoveFavoriteTaxo) {
      try {
        const response: any = yield call(helperRequestMyTicketListing, payload);
        yield put(receiveMyTicketsListingAction(response));
      } catch (error) {}
    }
  );
}

export function* requestMyContentListing(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_MY_CONTENT_LISTING,
    function* ({ payload }: RequestRemoveFavoriteTaxo) {
      try {
        const response: any = yield call(
          helperRequestMyContentListing,
          payload
        );
        yield put(receiveMyContentListingAction(response));
      } catch (error) {}
    }
  );
}

export function* requestHDTicketsListing(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_HD_TICKET_LISTING,
    function* ({ payload }: RequestRemoveFavoriteTaxo) {
      try {
        const response: any = yield call(helperRequestHDTicketListing, payload);
        yield put(receiveHDTicketListingAction(response));
      } catch (error) {}
    }
  );
}

export function* requestHDContentListing(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_HD_CONTENT_LISTING,
    function* ({ payload }: RequestRemoveFavoriteTaxo) {
      try {
        const response: any = yield call(
          helperRequestHDContentListing,
          payload
        );
        yield put(receiveHDContentListingAction(response));
      } catch (error) {}
    }
  );
}

export function* requestSaveTicketInputSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SAVE_TICKET_INPUT,
    function* ({ payload }: RequestSaveTicketInput) {
      try {
        const { statusCode, data } = yield call(
          helperRequestSaveTicketInput,
          payload
        );

        yield put(receiveSaveTicketInputAction(data));
      } catch (error) {}
    }
  );
}

export function* requestSaveContentInputSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SAVE_CONTENT_INPUT,
    function* ({ payload }: RequestSaveTicketInput) {
      try {
        const response: any = yield call(
          helperRequestSaveContentInput,
          payload
        );
        yield put(receiveSaveContentInputAction(response));
      } catch (error) {}
    }
  );
}

export function* requestStatusFilterSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_STATUS_FILTER,
    function* ({ payload }: RequestStatusFilter) {
      try {
        const response: any = yield call(helperRequestStatusFilter, payload);
        yield put(receiveStatusFilterAction(response));
      } catch (error) {}
    }
  );
}
export function* requestContentTypeFilterSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_CONTENT_TYPE_FILTER,
    function* (): any {
      try {
        const response: any = yield call(helperRequestContentTypeFilter);
        yield put(receiveContentTypeFilterAction(response));
      } catch (error) {}
    }
  );
}

export function* requestPriorityDropDownSaga(): any {
  yield takeEvery(AuthActionTypes.REQUEST_PRIORITY_DROPDOWN, function* (): any {
    try {
      const response: any = yield call(helperRequestPriorityDropdownList);
      yield put(receivePriorityDropdownAction(response));
    } catch (error) {}
  });
}

export function* requestCompanyDropDownSaga(): any {
  yield takeEvery(AuthActionTypes.REQUEST_COMPANY_DROPDOWN, function* (): any {
    try {
      const response: any = yield call(helperRequestCompanyDropdownList);
      yield put(receiveCompanyDropdownAction(response));
    } catch (error) {}
  });
}

export function* requestDetailsSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_DETAILS,
    function* ({ payload }: RequestDetails) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveDetailsAction(response));
      } catch (error) {}
    }
  );
}

export function* requestEditTicketDetailsSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_EDIT_TICKET,
    function* ({ payload }: RequestEditTicketDetails) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveEditTicketAction(response));
      } catch (error) {}
    }
  );
}

export function* requestEditTicketSharedWithSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_EDIT_SHARED_WITH,
    function* ({ payload }: RequestEditTicketDetails) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveEditSharedWithTicketAction(response));
      } catch (error) {}
    }
  );
}

export function* requestEditTicketTaxoSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_EDIT_TAXO_TICKET,
    function* ({ payload }: RequestEditTicketDetails) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveEditTaxoTicketAction(response));
      } catch (error) {}
    }
  );
}

export function* requestDeleteRecordSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_DELETE_RECORD,
    function* ({ payload }: RequestDelete) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveDeleteAction(response));
      } catch (error) {}
    }
  );
}

export function* requestCommunicationFromSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_COMMUNICATION_FROM,
    function* ({ payload }: RequestCommunicationFrom) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveCommunicationFromAction(response));
      } catch (error) {}
    }
  );
}

export function* requestCommunicationActionsSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_COMMUNICATION_ACTIONS,
    function* ({ payload }: RequestCommunicationActions) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveCommunicationActionsAction(response));
      } catch (error) {}
    }
  );
}

export function* requestClassificationFromClassSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_CLASSIFICATION_FROM_CLASS,
    function* ({ payload }: requestClassificationFromClass) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveClassificationFromClassAction(response));
      } catch (error) {}
    }
  );
}

export function* requestMoveToAnotherHdSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_MOVE_TO_ANOTHER_HD,
    function* ({ payload }: requestMoveToAnotherHd) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveMoveToAnotherHdAction(response));
      } catch (error) {}
    }
  );
}

export function* requestDuplicate(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_DUPLICATE,
    function* ({ payload }: requestDuplicate) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveDuplicateAction(response));
      } catch (error) {}
    }
  );
}

export function* requestGetFileAttachmentsSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_FILE_ATTACHMENT,
    function* ({ payload }: requestFileAttachment) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveFileAttachmentAction(response));
      } catch (error) {}
    }
  );
}

export function* requestDeleteTicketSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_DELETE_TICKET,
    function* ({ payload }: requestDeleteTicket) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveDeleteTicketAction(response));
      } catch (error) {}
    }
  );
}

export function* requestCommunicationThreadsSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_COMMUNICATION_THREADS,
    function* ({ payload }: RequestCommunicationThreads) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveCommunicationThreadsAction(response));
      } catch (error) {}
    }
  );
}

export function* requestRelatedDataSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_RELATED_DATA,
    function* ({ payload }: RequestRelatedData) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveRelatedDataAction(response));
      } catch (error) {}
    }
  );
}

export function* requestUploadDataSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_UPLOAD_DATA,
    function* ({ payload }: RequestUploadData) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveUploadDataAction(response));
      } catch (error) {}
    }
  );
}
export function* requestUploadDataDeleteSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_UPLOAD_DATA_DELETE,
    function* ({ payload }: RequestUploadDataDelete) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveUploadDataDeleteAction(response));
      } catch (error) {}
    }
  );
}

export function* requestAddRelatedDataSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_ADD_RELATED_DATA,
    function* ({ payload }: RequestAddRelatedData) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveAddRelatedDataAction(response));
      } catch (error) {}
    }
  );
}
export function* requestAssignToSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_ASSIGN_TO,
    function* ({ payload }: RequestAssignToUser) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveAssignToAction(response));
      } catch (error) {}
    }
  );
}

export function* requestWorkLogSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_WORK_LOG_THREADS,
    function* ({ payload }: RequestWorkLog) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveWorkLogThreadsAction(response));
      } catch (error) {}
    }
  );
}
export function* requestCommunicationLogSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_COMMUNICATION_LOG_THREADS,
    function* ({ payload }: RequestCommunicationLog) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveCommunicationLogAction(response));
      } catch (error) {}
    }
  );
}

export function* requestInsertCommunicationSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_INSERT_COMMUNICATION,
    function* ({ payload }: RequestInsertCommunication) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveInsertCommunicationAction(response));
      } catch (error) {}
    }
  );
}

export function* requestInformationUpdateSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_INFORMATION_UPDATE,
    function* ({ payload }: RequestInformationStatus) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveInformationUpdateAction(response));
      } catch (error) {}
    }
  );
}
export function* requestNotificationUpdateSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_NOTIFICATION_UPDATE,
    function* ({ payload }: RequestNotificationStatus) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveNotificationUpdateAction(response));
      } catch (error) {}
    }
  );
}

export function* requestSearchableUpdateSaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SEARCHABLE_UPDATE,
    function* ({ payload }: RequestSearchableStatus) {
      try {
        const { url, request } = payload;
        const response: any = yield call(APIRequest, url, request);
        yield put(receiveSearchableUpdateAction(response));
      } catch (error) {}
    }
  );
}

export function* requestSearchQuerySaga(): any {
  yield takeEvery(
    AuthActionTypes.REQUEST_SEARCH_QUERY,
    function* ({ payload }: RequestSearchableStatus) {
      try {
        const { url, request, body } = payload;
        const response: any = yield call(APIRequest, url, request, body);
        yield put(receiveSearchQueryAction(response));
      } catch (error) {}
    }
  );
}

function* authSaga() {
  yield all([
    fork(requestSearchQuerySaga),
    fork(requestSearchableUpdateSaga),
    fork(requestNotificationUpdateSaga),
    fork(requestInformationUpdateSaga),
    fork(requestInsertCommunicationSaga),
    fork(requestCommunicationLogSaga),
    fork(requestWorkLogSaga),
    fork(requestAssignToSaga),
    fork(requestAddRelatedDataSaga),
    fork(requestUploadDataDeleteSaga),
    fork(requestUploadDataSaga),
    fork(requestRelatedDataSaga),
    fork(requestCommunicationThreadsSaga),
    fork(requestDeleteTicketSaga),
    fork(requestGetFileAttachmentsSaga),
    fork(requestDuplicate),
    fork(requestMoveToAnotherHdSaga),
    fork(requestClassificationFromClassSaga),
    fork(requestCommunicationActionsSaga),
    fork(requestCommunicationFromSaga),
    fork(requestDeleteRecordSaga),
    fork(requestEditTicketTaxoSaga),
    fork(requestEditTicketSharedWithSaga),
    fork(requestEditTicketDetailsSaga),
    fork(requestCompanyDropDownSaga),
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(requestShareWithListingTicketInput),
    fork(requestHelpDeskListingDropdown),
    fork(requestRelatedTicketSaga),
    fork(requestRelatedContentSaga),
    fork(requestSearchContentByTitleSaga),
    fork(requestSearchTicketBySubjectSaga),
    fork(requestClassificationNodeSaga),
    fork(requestSearchTaxoByNameSaga),
    fork(requestClassificationNodeChildSaga),
    fork(requestFavoriteTaxoSaga),
    fork(requestAddFavoriteTaxoSaga),
    fork(requestRemoveFavoriteTaxoSaga),
    fork(requestMyTicketsListing),
    fork(requestMyContentListing),
    fork(requestHDContentListing),
    fork(requestHDTicketsListing),
    fork(requestSaveTicketInputSaga),
    fork(requestSaveContentInputSaga),
    fork(requestStatusFilterSaga),
    fork(requestContentTypeFilterSaga),
    fork(requestPriorityDropDownSaga),
    fork(requestDetailsSaga),
  ]);
}

export default authSaga;
