// @ts-nocheck
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

// layouts
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import HorizontalLayout from "../layouts/Horizontal/";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import JwtToken from "./JwtToken";

// constants
import { LayoutTypes } from "../constants";

// hooks
import { useRedux } from "../hooks";

// lazy load all the views
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
// dashboards
const DashBoard1 = React.lazy(() => import("../pages/Dashboard/index"));

// base ui
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvases"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const Carousel = React.lazy(() => import("../pages/uikit/Carousel"));
const EmbedVedio = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const PopoversAndTooltips = React.lazy(
  () => import("../pages/uikit/PopoversAndTooltips")
);
const GeneralUI = React.lazy(() => import("../pages/uikit/GeneralUI"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));

// extended ui
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSlider"));
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));
const Tourpage = React.lazy(() => import("../pages/uikit/TourPage"));
const TreeViewExample = React.lazy(() => import("../pages/uikit/TreeView"));

// icons
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons"));
const Dripicons = React.lazy(() => import("../pages/icons/DripiIcons"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons")
);
const ThemifyIcons = React.lazy(() => import("../pages/icons/ThemifyIcons"));

// forms
const NewTicketInput = React.lazy(() => import("../pages/TicketInput"));
const ContentInput = React.lazy(() => import("../pages/ContentInput"));
const ReadOnlyDetails = React.lazy(() => import("../pages/ReadOnlyDetails"));

const MyTicket = React.lazy(() => import("../pages/MyTicket"));
const HdTickets = React.lazy(() => import("../pages/HdTickets"));
const HdContent = React.lazy(() => import("../pages/HdContent"));
const ProfileDetails = React.lazy(() => import("../pages/Profile"));
const Search = React.lazy(() => import("../pages/Search"));
const TicketDetails = React.lazy(() => import("../pages/TicketDetails"));
const Details = React.lazy(() => import("../pages/Details"));
const ContentDetailsNew = React.lazy(
  () => import("../pages/ContentDetailsNew")
);
const MyContent = React.lazy(() => import("../pages/MyContent"));

const MainContact = React.lazy(() => import("../pages/MainContact"));
const ContentCollector = React.lazy(() => import("../pages/ContentCollector"));
const MyFavorites = React.lazy(() => import("../pages/MyFavorites"));

// charts
const ApexChart = React.lazy(() => import("../pages/chart/ApexChart"));
const ChartJs = React.lazy(() => import("../pages/chart/ChartJs"));

// lamding
const Landing = React.lazy(() => import("../pages/Landing"));
const Home = React.lazy(() => import("../pages/Home"));
const WhatsNew = React.lazy(() => import("../pages/WhatsNew"));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: "/", element: <Root /> },
    { path: "/jwt-token", element: <JwtToken /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "auth",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            {
              path: "register",
              element: <LoadComponent component={Register} />,
            },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            {
              path: "lock-screen",
              element: <LoadComponent component={LockScreen} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <PrivateRoute roles={"Admin"} component={Layout} />,
      children: [
        {
          path: "dashboard/default",
          element: <LoadComponent component={DashBoard1} />,
        },

        {
          path: "base-ui",
          children: [
            {
              path: "buttons",
              element: <LoadComponent component={Buttons} />,
            },
            {
              path: "cards",
              element: <LoadComponent component={Cards} />,
            },
            {
              path: "avatars",
              element: <LoadComponent component={Avatars} />,
            },
            {
              path: "tabs-accordions",
              element: <LoadComponent component={TabsAccordions} />,
            },
            {
              path: "notifications",
              element: <LoadComponent component={Notifications} />,
            },
            {
              path: "modals",
              element: <LoadComponent component={Modals} />,
            },
            {
              path: "progress",
              element: <LoadComponent component={Progress} />,
            },
            {
              path: "offcanvas",
              element: <LoadComponent component={Offcanvases} />,
            },
            {
              path: "placeholders",
              element: <LoadComponent component={Placeholders} />,
            },
            {
              path: "spinners",
              element: <LoadComponent component={Spinners} />,
            },
            {
              path: "images",
              element: <LoadComponent component={Images} />,
            },
            {
              path: "carousel",
              element: <LoadComponent component={Carousel} />,
            },
            {
              path: "embedvideo",
              element: <LoadComponent component={EmbedVedio} />,
            },
            {
              path: "dropdowns",
              element: <LoadComponent component={Dropdowns} />,
            },
            {
              path: "popovers-tooltips",
              element: <LoadComponent component={PopoversAndTooltips} />,
            },
            {
              path: "general",
              element: <LoadComponent component={GeneralUI} />,
            },
            {
              path: "typography",
              element: <LoadComponent component={Typography} />,
            },
            {
              path: "grid",
              element: <LoadComponent component={Grid} />,
            },
          ],
        },

        {
          path: "extended-ui",
          children: [
            {
              path: "nestable",
              element: <LoadComponent component={NestableList} />,
            },
            {
              path: "rangesliders",
              element: <LoadComponent component={RangeSliders} />,
            },
            {
              path: "sweet-alert",
              element: <LoadComponent component={SweetAlerts} />,
            },
            {
              path: "tour",
              element: <LoadComponent component={Tourpage} />,
            },
            {
              path: "treeview",
              element: <LoadComponent component={TreeViewExample} />,
            },
          ],
        },
        {
          path: "icons",
          children: [
            {
              path: "feather",
              element: <LoadComponent component={FeatherIcons} />,
            },
            {
              path: "mdi",
              element: <LoadComponent component={MDIIcons} />,
            },
            {
              path: "dripicons",
              element: <LoadComponent component={Dripicons} />,
            },
            {
              path: "font-awesome",
              element: <LoadComponent component={FontAwesomeIcons} />,
            },
            {
              path: "themify",
              element: <LoadComponent component={ThemifyIcons} />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "profile",
              element: <LoadComponent component={ProfileDetails} />,
            },
          ],
        },

        {
          path: "",
          children: [
            {
              path: "content-input",
              element: <LoadComponent component={ContentInput} />,
            },
            {
              path: "content/my_content/draft",
              element: <LoadComponent component={ContentInput} />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "contact",
              element: <LoadComponent component={MainContact} />,
            },
            {
              path: "content-collector",
              element: <LoadComponent component={ContentCollector} />,
            },
            {
              path: "favourites",
              element: <LoadComponent component={MyFavorites} />,
            },
          ],
        },
        {
          path: "ticket",
          children: [
            {
              path: "input",
              element: <LoadComponent component={NewTicketInput} />,
            },
            {
              path: "my_ticket",
              element: <LoadComponent component={MyTicket} />,
            },
            {
              path: "my_ticket/details",
              element: <LoadComponent component={Details} />,
            },
            {
              path: "my_ticket/draft",
              element: <LoadComponent component={NewTicketInput} />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "hd_ticket",
              element: <LoadComponent component={HdTickets} />,
            },
            {
              path: "hd_ticket/:userId",
              element: <LoadComponent component={Details} />,
            },
            {
              path: "draft",
              element: <LoadComponent component={NewTicketInput} />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "hd_content",
              element: <LoadComponent component={HdContent} />,
            },
            {
              path: "hd_content/details",
              element: <LoadComponent component={Details} />,
            },
            {
              path: "hd_content/draft",
              element: <LoadComponent component={ContentInput} />,
            },
          ],
        },

        {
          path: "",
          children: [
            {
              path: "Home",
              element: <LoadComponent component={Home} />,
            },
            {
              path: "whats-new",
              element: <LoadComponent component={WhatsNew} />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "search",
              element: <LoadComponent component={Search} />,
            },
            {
              path: "read-only/details",
              element: <LoadComponent component={ReadOnlyDetails} />,
            },
          ],
        },
        {
          path: "content",
          children: [
            {
              path: "my_content",
              element: <LoadComponent component={MyContent} />,
            },
            {
              path: "my_content/details",
              element: <LoadComponent component={Details} />,
            },
          ],
        },
        {
          path: "charts",
          children: [
            {
              path: "apex",
              element: <LoadComponent component={ApexChart} />,
            },
            {
              path: "chartjs",
              element: <LoadComponent component={ChartJs} />,
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
